import React from "react";
import "./index.css";

const Banner = () => {
  return (
    <div className="Banner">
      <h1>Coming Soon!</h1>
      <p>Transform Your Digital Presence with newBuz!</p>
    </div>
  );
};

export default Banner;
