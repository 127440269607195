import React, { useState } from "react";
import "./index.css";
import Logo from "../../Images/newBuzLogo3.PNG";

const Navbar = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  console.log("☕❓ ⇒⇒ ~ Navbar ~ showMobileMenu:", showMobileMenu);

  return (
    <div className="Navbar">
      <div className="div">/</div>
      <img className="Logo" src={Logo} alt="Logo" />
      <input
        onClick={() => setShowMobileMenu((prevState) => !prevState)}
        type="checkbox"
        id="checkbox"
      />
      <label for="checkbox" class="toggle">
        <div class="bars" id="bar1"></div>
        <div class="bars" id="bar2"></div>
        <div class="bars" id="bar3"></div>
      </label>
      {showMobileMenu && (
        <div className="extra-navbar">
          <h2>Coming Soon!</h2>
        </div>
      )}
    </div>
  );
};

export default Navbar;
