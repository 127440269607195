import React from "react";
import "./index.css";
import Navbar from "../../Components/Navbar";
import Banner from "../../Components/Banner";
// import Form from "../../Components/Form";

const index = () => {
  return (
    <div className="homepage">
      <Navbar />
      <Banner />
      {/* <Form /> */}
    </div>
  );
};

export default index;
